<template>
  <nav
    class="pages"
    aria-label="Pagination"
  >
    <ul class="pages__list">
      <li
        v-if="currentPage > 1"
        class="pages__item pages__item--previous"
      >
        <button
          type="button"
          aria-label="go to previous page"
          class="pages__link button button--link"
          @click="previous()"
        >
          <span>Previous</span>
          <IconArrow class="icon icon--arrow icon--arrow-left" />
        </button>
      </li>

      <template
        v-for="page in totalPages"
        :key="page"
      >
        <li
          v-if="inRange(page)"
          :class="{ 'pages__item--current': parseInt(currentPage) === page }"
          class="pages__item"
        >
          <AppLink
            :to="pageUrl(page)"
            class="pages__link"
            :aria-label="'go to page ' + page"
            @click="updatePage(page)"
          >
            <span>{{ page }}</span>
          </AppLink>
        </li>
      </template>

      <li
        v-if="currentPage < totalPages"
        class="pages__item pages__item--next"
      >
        <button
          type="button"
          aria-label="go to next page"
          class="pages__link button button--link "
          @click="next()"
        >
          <span>Next</span>
          <IconArrow class="icon icon--arrow icon--arrow-right" />
        </button>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
const route = useRoute()
const router = useRouter()
const surroundActivePage = 2

const props = defineProps<{
  currentPage: number
  totalPages: number
}>()

const { currentPage, totalPages } = toRefs(props)

const emit = defineEmits(['updatePage'])

const updatePage = (page: number) => {
  emit('updatePage', page)
  useScrollToTop()
}

const previous = () => {
  if (currentPage.value === 1) {
    return
  }

  updatePage(currentPage.value - 1)

  router.push(pageUrl(currentPage.value - 1))
}

const next = () => {
  if (currentPage.value >= totalPages.value) {
    return
  }

  updatePage(currentPage.value + 1)

  router.push(pageUrl(currentPage.value + 1))
}

function inRange(page: number) {
  return (page >= (parseInt(currentPage.value) - surroundActivePage))
    && (page <= (parseInt(currentPage.value) + surroundActivePage))
}

function getParams() {
  // Create URLSearchParams from this.$route.query
  return new URLSearchParams(Object.entries(route.query).map(([k, v]) => {
    return k + '=' + v
  }).join('&'))
}

function pageUrl(page: number) {
  const params = getParams()

  params.delete('p')
  if (page !== 1) {
    params.set('p', `${page}`)
  }

  return route.path + (params.toString().length > 0 ? '?' + params.toString() : '')
}
</script>

<style src="~layers/app/components/Paginator/Pages.css" />
